<template>
    <div class="home">
        <div class="position-relative">
            <img src="../assets/img/home-bg.png" class="mw-100 home__img" alt="" />
            <router-link to="/inscription" class="home__btn-register btn btn-primary">
                <span>JE M'INSCRIS</span>
            </router-link>
        </div>

        <NavigationLinks class="mt-5 mb-4" />

        <div class="text-center my-4 arial-rounded home__date">MERCREDI 26 MARS 2025</div>

        <MyCountdown :date="countdown_end" class="my-2" />

        <div class="text-center my-5">
            <div class="fs-4 px-xl-5--5">
                Le succès des deux premières éditions de LADYj. Tech nous conduit cette année à renouveler l'expérience
                dans une troisième édition riche en nouveautés !
            </div>
            <div class="fs-2 my-4 text-center">
                <span class="arial-rounded">Célébrer les Femmes de la Tech :</span>
                <br />
                1 Evénement unique avec 3 séquences, 2 thématiques nouvelles :
                <br />
                <span class="fw-bold">Industrie et International !</span>
            </div>
        </div>

        <!-- >DIJ'INNOV CONNECT -->
        <div class="row home__programme my-5">
            <div class="col-sm-auto col-12 d-flex-center flex-column">
                <span class="home__programme__title-1">DIJ'INNOV</span>
                <span class="home__programme__title-2">CONNECT</span>
            </div>
            <div class="col d-flex align-items-center">
                <div class="bg-green-light w-100 mx-sm-0 mx-5--5 py-sm-0 py-0--5" style="height: 1px"></div>
            </div>
            <div class="col-sm-auto col-12 d-flex-center">
                <span class="mb-4 fs-2">Session de 14h à 17h</span>
            </div>
            <div class="col-12">
                <div class="fs-4 border-start ps-4 border-green-light border-3">
                    <div class="row">
                        <div class="col-12">
                            <p class="fw-bold">Séquence 100 % dédiée aux collaborations de R&D et d'innovation !</p>
                            <p>En un seul lieu, un temps privilégié pour :</p>
                            <p class="d-flex gap-4 align-items-baseline">
                                <i class="fa-solid fa-arrow-right text-green-light"></i>
                                <span>Découvrir le potentiel de la recherche publique de notre territoire</span>
                            </p>
                            <p class="d-flex gap-4 align-items-baseline">
                                <i class="fa-solid fa-arrow-right text-green-light"></i>
                                <span>Identifier et échanger avec vos futurs partenaires innovation</span>
                            </p>
                            <p class="d-flex gap-4 align-items-baseline">
                                <i class="fa-solid fa-arrow-right text-green-light"></i>
                                <span>
                                    Approfondir votre connaissance des dispositifs de financements R&D, avec un focus
                                    sur le Programme Cifre
                                </span>
                            </p>
                            <p class="d-flex gap-4 align-items-baseline">
                                <i class="fa-solid fa-arrow-right text-green-light"></i>
                                <span>
                                    Stands, pitchs, présentation de dispositifs, rdv personnalisés … tout pour innover
                                    en convivialité !
                                </span>
                            </p>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-stretch gap-4 flex-wrap">
                            <router-link
                                to="/inscription"
                                class="btn btn-lg my-3 px-4 py-2--5 fs-6 btn-primary home__programme__action"
                            >
                                JE M'INSCRIS
                            </router-link>
                            <router-link
                                to="/opportunites-laboratoires"
                                class="btn btn-lg my-3 px-4 py-2--5 fs-6 btn-green-light home__programme__action"
                            >
                                DÉCOUVRIR LES OPPORTUNITÉS
                                <br />
                                DES LABORATOIRES
                            </router-link>
                            <router-link
                                to="/depot-problematique"
                                class="btn btn-lg my-3 px-4 py-2--5 fs-6 btn-green-dark home__programme__action"
                            >
                                ENTREPRISES, DÉPOSEZ
                                <br />
                                VOS EXPRESSIONS DE BESOINS
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--  Lady's Industrie -->
        <div class="row home__programme my-5">
            <div class="col-lg-auto col-12 d-flex-center flex-column">
                <span class="home__programme__title-1"> LADY'S </span>
                <span class="home__programme__title-2">INDUSTRIE</span>
            </div>
            <div class="col d-flex align-items-center">
                <div class="bg-primary w-100 mx-lg-0 mx-5--5 py-lg-0 py-0--5" style="height: 1px"></div>
            </div>
            <div class="col-lg-auto col-12 d-flex-center">
                <span class="mb-4 fs-2">Session de 15h à 16h</span>
            </div>
            <div class="col-12">
                <div class="fs-4 border-start ps-4 border-primary border-3">
                    <div class="row">
                        <div class="col-12">
                            <p class="fw-bold">Valoriser les compétences des femmes dans les métiers de l'industrie.</p>
                            <p>Venez découvrir des parcours inspirants et des stratégies concrètes.</p>
                            <p>Au programme :</p>
                            <p class="d-flex gap-4 align-items-baseline">
                                <i class="fa-solid fa-arrow-right text-primary"></i>
                                <span>
                                    Introduction : Présentation de Bourgogne Industrie avec des indicateurs spécifiques
                                    sur la place des femmes dans le secteur industriel, par David MARTI, Président de la
                                    Communauté Urbaine Creusot Montceau.
                                </span>
                            </p>
                            <p class="d-flex gap-4 align-items-baseline mb-0">
                                <i class="fa-solid fa-arrow-right text-primary"></i>
                                <span>
                                    Témoignages et échanges : 3 intervenantes partageront leurs expériences autour de
                                    deux thèmes :
                                </span>
                                <br />
                            </p>
                            <p class="ps-5">
                                <span>
                                    - Développer les carrières féminines dans l'industrie : Présentation de leur
                                    parcours, des défis et des succès rencontrés.
                                </span>
                                <br />
                                <span>
                                    - Attirer les femmes vers les métiers de l'industrie : stratégies et exemples de
                                    projets innovants pour renforcer l'attractivité du secteur.
                                </span>
                            </p>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-stretch gap-4">
                            <router-link
                                to="/inscription"
                                class="btn btn-lg my-3 px-4 py-2--5 fs-6 btn-primary home__programme__action"
                            >
                                JE M'INSCRIS
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Tables rondes -->
        <div class="row home__programme my-5">
            <div class="col-sm-auto col-12 d-flex-center flex-column">
                <span class="home__programme__title-1">TABLES</span>
                <span class="home__programme__title-2">RONDES</span>
            </div>
            <div class="col d-flex align-items-center">
                <div class="bg-orange w-100 mx-sm-0 mx-5--5 py-sm-0 py-0--5" style="height: 1px"></div>
            </div>
            <div class="col-sm-auto col-12 d-flex-center">
                <span class="mb-4 fs-2">Session de 17h à 19h30</span>
            </div>
            <div class="col-12">
                <div class="fs-4 border-start ps-4 border-orange border-3">
                    <div class="row">
                        <div class="col-12">
                            <p class="fw-bold">
                                Vous qui êtes dirigeants et dirigeantes d'entreprises, universitaires, chercheurs et
                                chercheures, étudiants et étudiantes, acteurs de l'innovation… Participez nombreux à cet
                                éclairage international !
                            </p>
                            <p class="fw-bold">Femmes de la Tech et de l'industrie à l'International</p>
                            <p>
                                Seront présentes : Xavière Castano, Agathe Decherf, Birgitte Graverholt, Karine Lignel,
                                Laurence Medioni, Ivana Pibiri, Florence Poulet, Marianne Sécheresse, Eva Cathrine
                                Skuthe, Stéphanie Wicht.
                            </p>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-stretch gap-4">
                            <router-link
                                to="/inscription"
                                class="btn btn-lg px-4 py-2--5 fs-6 btn-primary home__programme__action"
                            >
                                JE M'INSCRIS
                            </router-link>
                            <button
                                @click="openProgramPdf"
                                class="btn btn-lg px-4 py-2--5 fs-6 btn-orange home__programme__action"
                            >
                                VOIR LE PRE-PROGRAMME
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-primary text-white fw-bold text-center rounded fs-4 p-4 my-5--5">
            LADYj.Tech 3 est labellisé French Tech BFC et intégré à l'agenda national de la Mission French Tech.
            <br />
            <br />
            LADYj.Tech 3 est également un Evénement du Pôle Universitaire d'Innovation.
        </div>

        <div class="d-flex flex-column align-items-center text-center my-5--5 px-3 px-md-5 px-xl-5--5">
            <span class="home__programme__title-2 mt-0">COCKTAIL</span>
            <div class="separation bg-blue"></div>
            <span class="mt-2 mb-3 fs-2">De 19H30 à 21H</span>
            <span class="fs-4">Venez profiter d'un moment d'échange et de partage</span>
        </div>
    </div>
</template>

<script>
import MyCountdown from "@/components/MyCountdown.vue";
import NavigationLinks from "@/components/NavigationLinks.vue";

export default {
    components: {
        MyCountdown,
        NavigationLinks,
    },
    data() {
        return {
            countdown_end: new Date("2025-03-26T14:00:00"),
        };
    },
    methods: {
        openProgramPdf() {
            window.open("/resources/PROGRAMME LADYj.Tech 3.pdf", "_blank");
        },
    },
};
</script>
